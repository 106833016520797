import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  InputBase as MuiInputBase,
  Grid as MuiGrid,
  Divider as MuiDivider,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Typography as MuiTypography,
  Link as MuiLink,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import axios from "axios";
import { Alert, useAlerts } from "common";
import PropTypes from "prop-types";
import { useStyles } from "app/outreach/OutreachDetailsStyles";
import { getDisplayPercentage } from "app/shared/utils";
import {
  URLs,
  StudyChannelOptions,
  OutreachSubchannel,
} from "app/shared/constants";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";

const OutreachDetails = (props) => {
  const {
    onClose,
    open,
    outreach,
    refresh,
    setRefresh,
    readOnly = false,
    summaryAccrual = false,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const { alert, setAlert, clearAlert } = useAlerts();
  const [updatedMatrixData, setUpdatedMatrixData] = useState(outreach || {});
  const history = useHistory();
  const location = useLocation();
  //to extract the studyId from pathname
  const index1 = location.pathname.lastIndexOf("/");
  const studyId = location.pathname.substring(10, index1);

  const [openASConfirmation, setOpenASConfirmation] = useState(false);
  const [isValidCheck, setIsValid] = useState(true);
  const [show, setShow] = useState(false);

  const collectData = (outreach) => {
    return {
      requestedQuantity: renderData(outreach.requestedQuantity),
      quantity: renderData(outreach.quantity),
      outreachSummary: outreach.outreachSummary,
      responseSummary: outreach.responseSummary,
      subjectSummary: outreach.subjectSummary,
    };
  };

  const renderData = (value) => {
    if (isNaN(parseInt(value))) {
      return 0;
    }
    return value;
  };

  const renderAbandonedUnsubscribedData = (value) => {
    if (isNaN(parseInt(value))) {
      return t(`ConductOutreachPage.tableColumnNA`);
    } else {
      return value;
    }
  };

  const renderPercentage = (value) => {
    if (isNaN(parseInt(value))) {
      return `${getDisplayPercentage(0)} %`;
    }
    return `${getDisplayPercentage(value)} %`;
  };

  const renderAbandonedUnsubscribedDataPercentage = (value) => {
    if (isNaN(parseInt(value))) {
      return t(`ConductOutreachPage.tableColumnNA`);
    } else {
      return `${getDisplayPercentage(value)} %`;
    }
  };

  const convertDataToExcel = (value) => {
    return [
      renderData(value.requestedQuantity),
      renderData(value.quantity),
      renderData(value.outreachSummary.bounced),
      renderPercentage(value.outreachSummary.bouncedPercentage),
      renderData(value.outreachSummary.failed),
      renderPercentage(value.outreachSummary.failedPercentage),
      renderData(value.outreachSummary.abandoned),
      renderPercentage(value.outreachSummary.abandonedPercentage),
      renderData(value.responseSummary.responded),
      renderPercentage(value.responseSummary.respondedPercentage),
      renderData(value.responseSummary.interested),
      renderPercentage(value.responseSummary.interestedPercentage),
      renderData(value.responseSummary.notInterested),
      renderPercentage(value.responseSummary.notInterestedPercentage),
      renderData(value.responseSummary.unsubscribed),
      renderPercentage(value.responseSummary.unsubscribedPercentage),
      renderData(value.subjectSummary.pepOnStudy),
      renderPercentage(value.subjectSummary.pepOnStudyPercentage),
    ].join("\t");
  };

  const intialData = collectData(updatedMatrixData);

  const abandonedValidation = (outreach) => {
    return outreach.channel !== StudyChannelOptions.DE && edit;
  };

  const unsubscribedValidation = (outreach) => {
    return (
      (outreach.channel === StudyChannelOptions.MC ||
        outreach.channel === StudyChannelOptions.MH) &&
      edit
    );
  };

  const pepOnStudyValidation = () => {
    return summaryAccrual && edit;
  };

  const editValidation = () => {
    return (
      summaryAccrual &&
      edit &&
      (outreach.channel === StudyChannelOptions.MC ||
        outreach.channel === StudyChannelOptions.MH)
    );
  };

  const checkNum = (val) => {
    if (isNaN(val) || val === undefined) {
      return 0;
    }
    return val;
  };

  const respondedCalculation = (values, setValues, newVal) => {
    const responseSummaryData = newVal;
    const totalNum = values.quantity;

    const num =
      checkNum(parseInt(responseSummaryData.interested)) +
      checkNum(parseInt(responseSummaryData.notInterested)) +
      checkNum(parseInt(responseSummaryData.unsubscribed));

    const percentage = (num / totalNum) * 100;
    const updatedData = {
      ...newVal,
      responded: num,
      respondedPercentage: percentage,
    };

    setValues((prevValues) => ({
      ...prevValues,
      // we use the name to tell Formik which key of `values` to update
      responseSummary: updatedData,
    }));
  };

  const calculatePercentage = (num) => {
    return (num / intialData.quantity) * 100;
  };

  const handleSaveMatrixData = (values, setSubmitting) => {
    (async () => {
      try {
        const response = await axios.put(
          `${URLs.GET_OUTREACH_URL_BASE}/${outreach.id}${URLs.PUT_OUTREACH_MATRIX_URL_BASE}`,
          values
        );

        const location = {
          state: null,
        };
        history.replace(location);
        setRefresh(!refresh);
        setEdit(false);
        setSubmitting(true);
        setUpdatedMatrixData(response.data);
        setShow(false);
      } catch (err) {
        // set error.message in AlertContext
        const location = {
          pathname: `/outreach/${studyId}/conduct`,
          state: { ...history.location.state, fromOutreachDetailsPopup: true },
        };
        history.replace(location);
        setAlert(
          "error",
          `${err.response?.data?.message || err.message}`,
          false,
          false
        );
        setEdit(false);
        setSubmitting(true);
        setUpdatedMatrixData(values);
        setShow(true);
      }
    })();
  };

  const handleClickCancel = (dirty) => {
    if (dirty) {
      setOpenASConfirmation(true);
    } else {
      onClose();
      setShow(true);
      clearAlert();
    }
  };
  const closeCancelASConfirmation = (cancelChoice) => {
    setOpenASConfirmation(false);
    if (cancelChoice) {
      onClose();
      clearAlert();
      setShow(true);
    }
  };

  const setFieldError = (values, updatedFormValue) => {
    const responseSummaryData = updatedFormValue.responseSummary;
    const outreachSummaryData = updatedFormValue.outreachSummary;
    const subjectSummaryData = updatedFormValue.subjectSummary;
    const outreachQuantity = values.quantity;

    const num =
      checkNum(parseInt(outreachSummaryData.bounced)) +
      checkNum(parseInt(outreachSummaryData.failed)) +
      checkNum(parseInt(responseSummaryData.abandoned)) +
      checkNum(parseInt(responseSummaryData.interested)) +
      checkNum(parseInt(responseSummaryData.notInterested)) +
      checkNum(parseInt(responseSummaryData.unsubscribed));

    const pepNumCalculation =
      outreachQuantity -
      (checkNum(parseInt(outreachSummaryData.bounced)) +
        checkNum(parseInt(outreachSummaryData.failed)));

    if (
      pepNumCalculation > 0 &&
      checkNum(parseInt(subjectSummaryData.pepEnrolled)) > pepNumCalculation
    ) {
      const location = {
        pathname: `/outreach/${studyId}/conduct`,
        state: { ...history.location.state, fromOutreachDetailsPopup: true },
      };
      history.replace(location);
      setIsValid(false);
      setAlert(
        "error",
        t(`OutreachDetails.pepOnStudyQuantityError`, {
          quantity: checkNum(parseInt(pepNumCalculation)),
        }),
        false,
        false
      );
      setShow(true);
    } else if (num > outreachQuantity) {
      const location = {
        pathname: `/outreach/${studyId}/conduct`,
        state: { ...history.location.state, fromOutreachDetailsPopup: true },
      };
      history.replace(location);
      setIsValid(false);
      setAlert(
        "error",
        t(`OutreachDetails.matricsSumQuantityError`),
        false,
        false
      );
      setShow(true);
    } else {
      setIsValid(true);
      setShow(false);
    }
  };

  const editDisableValidation = () => {
    return ![
      "FINISHED",
      "FINISHED_WITH_ERROR",
      "GENERATED",
      "GENERATED_WITH_ERROR",
    ].includes(outreach.status);
  };

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      scroll={"paper"}
      maxWidth={"xl"}
      fullWidth={false}
    >
      <ConfirmationDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        message={t(`OutreachDetails.confirmationMessageCancel`)}
        okLabel={t("ConductOutreachPage.yesLabel")}
        cancelLabel={t("ConductOutreachPage.noLabel")}
      />
      <MuiDialogTitle
        id="outreach-quantity-summary-title"
        className={classes.OutreachDetailsTitle}
      >
        {outreach.waveName
          ? t(`OutreachDetails.title`, { waveName: `'${outreach.waveName}'` })
          : t(`OutreachDetails.titleForTotals`)}
      </MuiDialogTitle>
      {show && alert.exists && <Alert />}
      <MuiTypography
        variant="body"
        style={{
          marginLeft: "25px",
          marginTop: "20px",
        }}
      >
        {t(`OutreachDetails.studyId`, {
          studyId: outreach.waveName ? outreach.studyId : outreach.id,
        })}
      </MuiTypography>
      {outreach.waveName && (
        <>
          <MuiTypography variant="body" className={classes.outreachDetailsTypo}>
            {t(`OutreachDetails.outreachId`, { outreachId: outreach.id })}
          </MuiTypography>

          {outreach.channel === StudyChannelOptions.DE &&
            outreach.subchannel === OutreachSubchannel.DEMKTO && (
              <>
                <MuiTypography
                  variant="body"
                  className={classes.outreachDetailsTypo}
                >
                  {t(`OutreachDetails.outreachTemplateName`, {
                    templateName: outreach.templateName,
                  })}
                </MuiTypography>
                <MuiTypography
                  variant="body"
                  className={classes.outreachDetailsTypo}
                >
                  {t(`OutreachDetails.outreachUrlLabel`)}
                  <MuiLink
                    href={outreach.programURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                    color="primary"
                  >
                    {outreach.programURL}
                  </MuiLink>
                </MuiTypography>
              </>
            )}
        </>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={intialData}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleSaveMatrixData(values, setSubmitting);
        }}
      >
        {({ values, isValid, dirty, isSubmitting, setValues }) => {
          return (
            <Form autoComplete="off">
              <MuiDialogContent>
                <CopyButton
                  labelText={t(`OutreachDetails.copyToClipboard`)}
                  clipboardText={convertDataToExcel(updatedMatrixData)}
                  active={edit}
                />

                <MuiTableContainer component={MuiPaper}>
                  <MuiTable
                    className={classes.outrachTableOutline}
                    size="small"
                  >
                    <MuiTableHead>
                      <MuiTableRow>
                        <MuiTableCell width="135" align="center">
                          {t(`OutreachDetails.requestedQuantity`)}
                        </MuiTableCell>
                        <MuiTableCell width="135" align="center">
                          {t(`OutreachDetails.outreachQuantity`)}
                        </MuiTableCell>
                        <MuiTableCell align="center">
                          {t(`OutreachDetails.bounced`)}
                        </MuiTableCell>
                        <MuiTableCell align="center">
                          {t(`OutreachDetails.failed`)}
                        </MuiTableCell>
                        <MuiTableCell align="center">
                          {t(`OutreachDetails.abandoned`)}
                        </MuiTableCell>
                        <MuiTableCell align="center">
                          {t(`OutreachDetails.responded`)}
                        </MuiTableCell>
                        <MuiTableCell align="center">
                          {t(`OutreachDetails.interested`)}
                        </MuiTableCell>
                        <MuiTableCell align="center">
                          {t(`OutreachDetails.notInterested`)}
                        </MuiTableCell>
                        <MuiTableCell align="center">
                          {t(`OutreachDetails.unsubscribed`)}
                        </MuiTableCell>
                        <MuiTableCell align="center">
                          {t(`OutreachDetails.pepOnStudy`)}
                        </MuiTableCell>
                      </MuiTableRow>
                    </MuiTableHead>
                    <MuiTableBody
                      className={classes.outreachTableCellCustomPadding}
                    >
                      <MuiTableRow>
                        <MuiTableCell>
                          <MuiGrid container>
                            <MuiGrid item xs>
                              <MuiInputBase
                                disabled
                                value={
                                  values.requestedQuantity &&
                                  renderData(values.requestedQuantity)
                                }
                              />
                            </MuiGrid>
                          </MuiGrid>
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiGrid container>
                            <MuiGrid item xs>
                              <MuiInputBase
                                disabled
                                value={
                                  values.quantity && renderData(values.quantity)
                                }
                              />
                            </MuiGrid>
                          </MuiGrid>
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiGrid container>
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="bounced"
                                id="bounced"
                                disabled={editValidation() || !edit}
                                value={
                                  values.outreachSummary
                                    ? renderData(values.outreachSummary.bounced)
                                    : ""
                                }
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    (e.target.value >= 0 &&
                                      e.target.value <= values.quantity)
                                  ) {
                                    const newVal = {
                                      ...values.outreachSummary,
                                      bounced: e.target.value,
                                      bouncedPercentage: calculatePercentage(
                                        e.target.value
                                      ),
                                    };
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      // we use the name to tell Formik which key of `values` to update
                                      outreachSummary: newVal,
                                    }));
                                    const updatedFormValue = {
                                      ...values,
                                      outreachSummary: newVal,
                                    };
                                    setFieldError(values, updatedFormValue);
                                  }
                                }}
                              />
                            </MuiGrid>
                            <MuiDivider orientation="vertical" flexItem />
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="bouncedPercentage"
                                id="bouncedPercentage"
                                disabled
                                value={
                                  values.outreachSummary
                                    ? renderPercentage(
                                        values.outreachSummary.bouncedPercentage
                                      )
                                    : ""
                                }
                              />
                            </MuiGrid>
                          </MuiGrid>
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiGrid container>
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="failed"
                                id="failed"
                                disabled={editValidation() || !edit}
                                value={
                                  values.outreachSummary
                                    ? renderData(values.outreachSummary.failed)
                                    : ""
                                }
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    (e.target.value >= 0 &&
                                      e.target.value <= values.quantity)
                                  ) {
                                    const newVal = {
                                      ...values.outreachSummary,
                                      failed: e.target.value,
                                      failedPercentage: calculatePercentage(
                                        e.target.value
                                      ),
                                    };
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      // we use the name to tell Formik which key of `values` to update
                                      outreachSummary: newVal,
                                    }));
                                    const updatedFormValue = {
                                      ...values,
                                      outreachSummary: newVal,
                                    };
                                    setFieldError(values, updatedFormValue);
                                  }
                                }}
                              />
                            </MuiGrid>
                            <MuiDivider orientation="vertical" flexItem />
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="failedPercentage"
                                id="failedPercentage"
                                disabled
                                value={
                                  values.outreachSummary
                                    ? renderPercentage(
                                        values.outreachSummary.failedPercentage
                                      )
                                    : ""
                                }
                              />
                            </MuiGrid>
                          </MuiGrid>
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiGrid container>
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="abandoned"
                                id="abandoned"
                                disabled={
                                  abandonedValidation(outreach) || !edit
                                }
                                value={
                                  values.responseSummary
                                    ? renderAbandonedUnsubscribedData(
                                        values.responseSummary.abandoned
                                      )
                                    : ""
                                }
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    (e.target.value >= 0 &&
                                      e.target.value <= values.quantity)
                                  ) {
                                    const newVal = {
                                      ...values.responseSummary,
                                      abandoned: e.target.value,
                                      abandonedPercentage: calculatePercentage(
                                        e.target.value
                                      ),
                                    };
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      // we use the name to tell Formik which key of `values` to update
                                      responseSummary: newVal,
                                    }));
                                    const updatedFormValue = {
                                      ...values,
                                      responseSummary: newVal,
                                    };
                                    setFieldError(values, updatedFormValue);
                                  }
                                }}
                              />
                            </MuiGrid>
                            <MuiDivider orientation="vertical" flexItem />
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="abandonedPercentage"
                                id="abandonedPercentage"
                                disabled
                                value={
                                  values.responseSummary
                                    ? renderAbandonedUnsubscribedDataPercentage(
                                        values.responseSummary
                                          .abandonedPercentage
                                      )
                                    : ""
                                }
                              />
                            </MuiGrid>
                          </MuiGrid>
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiGrid container>
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="responded"
                                id="responded"
                                disabled
                                value={
                                  values.responseSummary
                                    ? renderData(
                                        values.responseSummary.responded
                                      )
                                    : ""
                                }
                              />
                            </MuiGrid>
                            <MuiDivider orientation="vertical" flexItem />
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="respondedPercentage"
                                id="respondedPercentage"
                                disabled
                                value={
                                  values.responseSummary
                                    ? renderPercentage(
                                        values.responseSummary
                                          .respondedPercentage
                                      )
                                    : ""
                                }
                              />
                            </MuiGrid>
                          </MuiGrid>
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiGrid container>
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="interested"
                                id="interested"
                                disabled={editValidation() || !edit}
                                autoFocus={false}
                                value={
                                  values.responseSummary
                                    ? renderData(
                                        values.responseSummary.interested
                                      )
                                    : ""
                                }
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    (e.target.value >= 0 &&
                                      e.target.value <= values.quantity)
                                  ) {
                                    const newVal = {
                                      ...values.responseSummary,
                                      interested: e.target.value,
                                      interestedPercentage: calculatePercentage(
                                        e.target.value
                                      ),
                                    };
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      // we use the name to tell Formik which key of `values` to update
                                      responseSummary: newVal,
                                    }));
                                    respondedCalculation(
                                      values,
                                      setValues,
                                      newVal
                                    );
                                    const updatedFormValue = {
                                      ...values,
                                      responseSummary: newVal,
                                    };
                                    setFieldError(values, updatedFormValue);
                                  }
                                }}
                              />
                            </MuiGrid>
                            <MuiDivider orientation="vertical" flexItem />
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="interestedPercentage"
                                id="interestedPercentage"
                                disabled
                                value={
                                  values.responseSummary
                                    ? renderPercentage(
                                        values.responseSummary
                                          .interestedPercentage
                                      )
                                    : ""
                                }
                              />
                            </MuiGrid>
                          </MuiGrid>
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiGrid container>
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="notInterested"
                                id="notInterested"
                                disabled={editValidation() || !edit}
                                value={
                                  values.responseSummary
                                    ? renderData(
                                        values.responseSummary.notInterested
                                      )
                                    : ""
                                }
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    (e.target.value >= 0 &&
                                      e.target.value <= values.quantity)
                                  ) {
                                    const newVal = {
                                      ...values.responseSummary,
                                      notInterested: e.target.value,
                                      notInterestedPercentage:
                                        calculatePercentage(e.target.value),
                                    };
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      // we use the name to tell Formik which key of `values` to update
                                      responseSummary: newVal,
                                    }));
                                    respondedCalculation(
                                      values,
                                      setValues,
                                      newVal
                                    );
                                    const updatedFormValue = {
                                      ...values,
                                      responseSummary: newVal,
                                    };
                                    setFieldError(values, updatedFormValue);
                                  }
                                }}
                              />
                            </MuiGrid>
                            <MuiDivider orientation="vertical" flexItem />
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="notInterestedPercentage"
                                id="notInterestedPercentage"
                                disabled
                                value={
                                  values.responseSummary
                                    ? renderPercentage(
                                        values.responseSummary
                                          .notInterestedPercentage
                                      )
                                    : ""
                                }
                              />
                            </MuiGrid>
                          </MuiGrid>
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiGrid container>
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="unsubscribed"
                                id="unsubscribed"
                                disabled={
                                  unsubscribedValidation(outreach) || !edit
                                }
                                value={
                                  values.responseSummary
                                    ? renderAbandonedUnsubscribedData(
                                        values.responseSummary.unsubscribed
                                      )
                                    : ""
                                }
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    (e.target.value >= 0 &&
                                      e.target.value <= values.quantity)
                                  ) {
                                    const newVal = {
                                      ...values.responseSummary,
                                      unsubscribed: e.target.value,
                                      unsubscribedPercentage:
                                        calculatePercentage(e.target.value),
                                    };
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      // we use the name to tell Formik which key of `values` to update
                                      responseSummary: newVal,
                                    }));
                                    respondedCalculation(
                                      values,
                                      setValues,
                                      newVal
                                    );
                                    const updatedFormValue = {
                                      ...values,
                                      responseSummary: newVal,
                                    };
                                    setFieldError(values, updatedFormValue);
                                  }
                                }}
                              />
                            </MuiGrid>
                            <MuiDivider orientation="vertical" flexItem />
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="unsubscribedPercentage"
                                id="unsubscribedPercentage"
                                disabled
                                value={
                                  values.responseSummary
                                    ? renderAbandonedUnsubscribedDataPercentage(
                                        values.responseSummary
                                          .unsubscribedPercentage
                                      )
                                    : ""
                                }
                              />
                            </MuiGrid>
                          </MuiGrid>
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiGrid container>
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="pepEnrolled"
                                id="pepEnrolled"
                                disabled={!pepOnStudyValidation()}
                                value={
                                  values.subjectSummary
                                    ? renderData(
                                        values.subjectSummary.pepEnrolled
                                      )
                                    : ""
                                }
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    (e.target.value >= 0 &&
                                      e.target.value <= values.quantity)
                                  ) {
                                    const newVal = {
                                      ...values.subjectSummary,
                                      pepEnrolled: e.target.value,
                                      pepEnrolledPercentage:
                                        calculatePercentage(e.target.value),
                                    };
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      // we use the name to tell Formik which key of `values` to update
                                      subjectSummary: newVal,
                                    }));
                                    const updatedFormValue = {
                                      ...values,
                                      subjectSummary: newVal,
                                    };
                                    setFieldError(values, updatedFormValue);
                                  }
                                }}
                              />
                            </MuiGrid>
                            <MuiDivider orientation="vertical" flexItem />
                            <MuiGrid item xs>
                              <MuiInputBase
                                name="pepEnrolledPercentage"
                                id="pepEnrolledPercentage"
                                disabled
                                value={
                                  values.subjectSummary
                                    ? renderPercentage(
                                        values.subjectSummary
                                          .pepEnrolledPercentage
                                      )
                                    : ""
                                }
                              />
                            </MuiGrid>
                          </MuiGrid>
                        </MuiTableCell>
                      </MuiTableRow>
                    </MuiTableBody>
                  </MuiTable>
                </MuiTableContainer>
              </MuiDialogContent>
              <MuiDialogActions>
                {edit ? (
                  <MuiButton
                    type="submit"
                    color="primary"
                    disabled={
                      !isValid || !dirty || !isValidCheck || isSubmitting
                    }
                  >
                    {t(`OutreachDetails.save`)}
                  </MuiButton>
                ) : !readOnly ? (
                  <MuiButton
                    color="primary"
                    onClick={() => setEdit(true)}
                    disabled={
                      editDisableValidation() ||
                      ((outreach.channel === StudyChannelOptions.MC ||
                        outreach.channel === StudyChannelOptions.MH) &&
                        !summaryAccrual)
                      // || (outreach.channel === StudyChannelOptions.DE &&
                      // outreach.subchannel === OutreachSubchannel.DEMKTO)
                    }
                  >
                    {t(`Edit`)}
                  </MuiButton>
                ) : null}
                {edit ? (
                  <MuiButton
                    onClick={() => handleClickCancel(dirty)}
                    color="primary"
                  >
                    {t(`OutreachDetails.cancel`)}
                  </MuiButton>
                ) : (
                  <MuiButton
                    onClick={() => {
                      onClose();
                      clearAlert();
                    }}
                    color="primary"
                  >
                    {t(`Close`)}
                  </MuiButton>
                )}
              </MuiDialogActions>
            </Form>
          );
        }}
      </Formik>
    </MuiDialog>
  );
};
OutreachDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  outreach: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    requestedQuantity: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    outreachSummary: PropTypes.object.isRequired,
    responseSummary: PropTypes.object.isRequired,
    subjectSummary: PropTypes.object.isRequired,
    channel: PropTypes.string,
  }),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  readOnly: PropTypes.bool,
  summaryAccrual: PropTypes.bool,
};

const CopyButton = (props) => {
  const { t } = useTranslation();
  const { clipboardText, labelText, active } = props;
  const [buttonLabel, setButtonLabel] = useState(labelText);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(clipboardText).then(
      () => {
        setTemporaryButtonLabel(t(`OutreachDetails.copySuccessful`));
      },
      () => {
        setTemporaryButtonLabel(t(`OutreachDetails.copyFailed`));
      }
    );
  };

  const setTemporaryButtonLabel = (text) => {
    setButtonLabel(text);
    setTimeout(() => setButtonLabel(labelText), 3000);
  };

  return (
    <MuiButton onClick={copyToClipboard} color="primary" disabled={active}>
      {buttonLabel}
    </MuiButton>
  );
};
CopyButton.propTypes = {
  clipboardText: PropTypes.string,
  labelText: PropTypes.string,
  active: PropTypes.bool,
};

export default OutreachDetails;
